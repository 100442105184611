<template>
  <div
    class="group-post-detail-container"
    :class="{
      forbidden
    }"
  >
    <div class="group-title" v-if="group">
      <p>小组</p>
      <p>{{ group.title }}</p>
    </div>
    <!-- 详情 -->
    <div class="group-post__detail-container">
      <group-post-c
        render-type="single"
        :groupid="Number(groupid)"
        :postid="Number(postid)"
        @getGroup="onGetGroupInfo"
        @getPost="onGetPost"
      ></group-post-c>
    </div>
    <!-- 标语 -->
    <slogan text="同伴的标志" sign="✖"></slogan>
    <!-- 底部按钮 -->
    <div class="group__footer-fixed-nav">
      <div class="nav__item" @click="goBack"><strong>返回</strong></div>
      <div class="nav__item back" @click="goGroup"><strong>进入小组</strong></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import wxtool from '@/utils/wx-tool';
import groupPostC from './_post.vue';
import slogan from '../common/_slogan.vue';

export default {
  name: 'group-post-detail',
  props: ['groupid', 'postid'],
  components: {
    groupPostC,
    slogan,
  },
  data() {
    return {
      group: null,
      post: null,
      forbidden: false,
    };
  },
  computed: {
    ...mapState(['user']),
    reqestedAll() {
      return this.user !== null && this.group !== null;
    },
  },
  watch: {
    reqestedAll(val) {
      if (val) {
        wxtool.share({
          title: `${this.user.nickname} 和你分享了小组内容`,
          desc: `小组「${this.group.title}」`,
          link: `${process.env.VUE_APP_BASE}group/${this.groupid}/post/${this.postid}`,
          imgUrl: this.group.cover
            ? this.$root.$options.filters.parseImage(this.group.cover)
            : this.user.headimgurl,
        }, this.$route.fullPath);
      }
    },
  },
  mounted() {
    this.$root.$on('forbidden', () => {
      this.forbidden = true;
    });
    this.$root.$on('afterPostDeleted', () => {
      this.$router.go(-1);
    });
  },
  methods: {
    goGroup() {
      this.$router.push({
        path: `/group/${this.groupid}`,
      });
    },
    onGetGroupInfo(group) {
      this.group = group;
    },
    onGetPost(post) {
      this.post = post;
    },
  },
};
</script>

<style></style>
